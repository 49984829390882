<template>
  <div class="myspaceinfo_container">
    <h2 class="title_md right_container_sub_title">내 채널</h2>
    <div class="aplayz_channel myspace">
      <figure
        class="aplayz_channel_img_wrap cursor dim"
        @click="clickMyChannel($store.getters['player/getMyChannelInfo'], 'my')"
      >
        <div class="equalizer_wrap" v-if="isplay && isactivechannel && activetab === 'my'">
          <equaliaer-comp />
        </div>
        <img v-if="mychannelinfo" class="aplayz_channel_img" :src="imgUrl" alt="" />
      </figure>
      <div class="aplayz_channel_nm_wrap">
        <h3
          class="title_sm aplayz_channel_nm cursor"
          @click="clickMyChannel($store.getters['player/getMyChannelInfo'], 'my')"
        >
          {{ myChannelName }}
        </h3>
      </div>
    </div>
    <h3
      id="simlarity_title"
      class="title_md right_container_sub_title right_container_sub_title1 mt_28 similarity"
      v-if="similarChannelsList.length > 0"
    >
      유사 채널
      <div class="similarity_info_imgwrap">
        <img
          id="similarityImg"
          src="/media/img/player/info.png"
          class="similarity_info_img cursor"
          alt="플레이리스트 1시간 업데이트 정보"
          @mouseenter="similarInfoMouseEnter"
          @mouseleave="similarInfoMouseLeave"
        />
      </div>
    </h3>
    <ul
      class="similarity_list"
      v-if="similarChannelsList.length > 0"
      :class="{ no_recommend: similarChannelsList.length === 0 }"
    >
      <li
        class="aplayz_channel mb16 cursor"
        v-for="(item, idx) in similarChannelsList"
        :id="`similar_${item.channelId}`"
        :key="item.channelId"
        @click="clickMyChannel(item, 'similar')"
      >
        <figure class="aplayz_channel_img_wrap">
          <div
            class="equalizer_wrap"
            v-if="isplay && item.channelId === $store.getters.gettersChannelId && activetab === 'similar'"
          >
            <equaliaer-comp />
          </div>
          <img class="aplayz_channel_img" :src="item.brandId ? item.src : item.spaceImg" :alt="`유사채널_${idx}`" />
        </figure>
        <div class="aplayz_channel_nm_wrap">
          <h3 class="title_sm aplayz_channel_nm">{{ item.spaceNm }}</h3>
        </div>
      </li>
    </ul>
    <h3 class="title_md right_container_sub_title mt_28">즐겨찾기</h3>
    <ul
      class="similarity_list scroll"
      v-if="bookmarkChannelsList.length > 0"
      :class="{ no_recommend: similarChannelsList.length === 0, bookmark: similarChannelsList.length > 0 }"
    >
      <li
        class="aplayz_channel mb16"
        v-for="item in bookmarkChannelsList"
        :key="item.channelId"
        :id="`bookmark_${item.channelId}`"
      >
        <figure class="aplayz_channel_img_wrap cursor" @click="clickMyChannel(item, 'bookmark')">
          <div class="equalizer_wrap" v-if="isplay && item.channelId === $store.getters.gettersChannelId">
            <equaliaer-comp />
          </div>
          <img class="aplayz_channel_img" :src="`${baseUrl}${item.imgUrl}`" :alt="item.spaceNm" />
        </figure>
        <div id="channelname_parent" class="aplayz_channel_nm_wrap">
          <h3 class="title_sm aplayz_channel_nm cursor" @click="clickMyChannel(item, 'bookmark')">
            {{ item.spaceNm }}
          </h3>
        </div>
        <figure class="star_bookmark_wrap cursor" @click="setManageBookMark(item.channelId)">
          <img src="/media/img/star_after.png" alt="즐겨찾기" class="star star_bookmark" />
        </figure>
      </li>
    </ul>
    <p v-else class="no_list">마음에 드는 채널을 즐겨찾기 추가하시면, 더 편하게 이용하실 수 있어요.</p>
  </div>
</template>
<script>
import rolling from '@/utils/directive/rolling';
import { isNotPc } from '@/utils/Utils';
export default {
  name: 'MySpaceInfo',
  directives: {
    rolling
  },
  props: {
    isplay: {
      type: Boolean,
      required: false,
      default: false
    },
    isactivechannel: {
      type: Boolean,
      required: false,
      default: false
    },
    activetab: {
      type: String,
      required: false,
      default: ''
    },
    mychannelinfo: {
      type: Object,
      requried: false,
      default: () => {
        return {
          brandId: '',
          brandImg: '',
          channelId: '',
          channelType: '',
          domesticRate: '0',
          genre: '',
          imgUrl: '',
          period: '',
          playerinfofn: () => {},
          sector: '',
          spaceId: '',
          spaceImg: '',
          spaceNm: '',
          spaceType: '',
          src: ''
        };
      }
    },
    clickchannel: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      isPc: !isNotPc(),
      resizeEvent: null,
      outsideClick: null
    };
  },
  components: {
    'equaliaer-comp': () => import(`@/components/player/Equalizer.vue`)
  },
  methods: {
    makeSimilarityInfo() {
      let similarityContents = document.querySelector('#similaritycontents');
      const app = document.querySelector('.app');
      const similarityImg = document.querySelector('#similarityImg');
      const similarityText = document.querySelector('#aplayzplayerright');
      if (!similarityContents) {
        const { top, left } = similarityImg.getBoundingClientRect();
        const { left: similarityTextLeft } = similarityText.getBoundingClientRect();

        const { clientWidth } = similarityImg;
        similarityContents = document.createElement('p');
        similarityContents.id = 'similaritycontents';
        similarityContents.classList.add('similarity_info_contents');
        similarityContents.style.top = `${top + clientWidth + 10}px`;
        similarityContents.style.transform = 'translateX(0)';
        similarityContents.style.left = `${similarityTextLeft - 2}px`;
        similarityContents.style.zIndex = '99';
        similarityContents.innerHTML = `현재 내 공간과 유사한 음악 취향, 분위기를  가지고 있는 <br/> 다른 공간의 채널이에요.`;

        // 화살표 이미지
        const arrowTop = document.createElement('img');
        arrowTop.src = `/media/img/player/info_arrorw_top.png`;
        arrowTop.classList.add('similarity_info_contents_arrowtop_img');
        arrowTop.style.position = 'fixed';
        arrowTop.style.left = `${left}px`;
        arrowTop.style.transform = `translateX(-3px)`;
        arrowTop.style.top = `${top + clientWidth + 5}px`;
        arrowTop.style.zIndex = '99';
        arrowTop.id = 'simlarityImg';

        app.appendChild(arrowTop);

        // 닫기 이미지
        this.isPc = !isNotPc();
        if (!this.isPc) {
          const closeImg = document.createElement('img');
          closeImg.src = `/media/img/player/info_close.png`;
          closeImg.classList.add('curation_info_contents_img');

          closeImg.classList.add('cursor');
          similarityContents.appendChild(closeImg);
        }

        app.appendChild(similarityContents);

        this.outsideClick = $event => {
          if ($event.target !== similarityContents && $event.target !== similarityImg) {
            app.removeChild(similarityContents);
            app.removeChild(arrowTop);
            window.removeEventListener('click', this.outsideClick);
            window.removeEventListener('resize', this.resizeEvent);
            window.removeEventListener('scroll', this.resizeEvent);
          }
        };
        this.resizeEvent = () => {
          const { top, left } = similarityImg.getBoundingClientRect();
          similarityContents.style.top = `${top + clientWidth + 10}px`;
          similarityContents.style.left = `${left - 5}px`;
        };

        window.addEventListener('click', this.outsideClick);
        window.addEventListener('resize', this.resizeEvent);
        window.addEventListener('scroll', this.resizeEvent);
      }
    },
    deleteSimilartyInfo() {
      const similaritycontents = document.querySelector('#similaritycontents');
      const simlarityImg = document.querySelector('#simlarityImg');
      const app = document.querySelector('.app');
      if (similaritycontents) {
        app.removeChild(similaritycontents);
        app.removeChild(simlarityImg);
        window.removeEventListener('click', this.outsideClick);
        window.removeEventListener('resize', this.resizeEvent);
      }
    },
    similarInfoMouseEnter() {
      if (this.isPc) {
        this.makeSimilarityInfo();
      }
    },
    similarInfoMouseLeave() {
      if (this.isPc) {
        this.deleteSimilartyInfo();
      }
    },
    clickMyChannel(item, type) {
      this.clickchannel(item, type);
    },
    setManageBookMark(channelId) {
      const addArray = [
        ...this.$store.getters['player/getPopularityChannelList'],
        ...this.$store.getters['player/getDesignatedRecommendedChannels']
      ];
      const findChannelId = addArray.find(item => item.channelId === channelId);
      const recomId = findChannelId.recomId;
      this.$store.dispatch('player/manageBookmark', {
        channelId: recomId
      });
    }
  },
  computed: {
    imgUrl() {
      if (this.mychannelinfo) {
        if (this.mychannelinfo.brandId) {
          return this.mychannelinfo.imgUrl;
        }
        return this.mychannelinfo.spaceImg;
      }

      return '';
    },
    myChannelName() {
      if (this.mychannelinfo) {
        return this.mychannelinfo.spaceNm;
      }

      return '';
    },
    similarChannelsList() {
      return this.$store.getters['player/getSimilarChannelList'];
    },

    bookmarkChannelsList() {
      return this.$store.getters['player/getBookmarkChannels'];
    }
  }
};
</script>
<style scoped src="@/assets/css/player/myspaceinfo.css"></style>
